<template>
  <v-dialog v-model="dialog" max-width="900px">
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          New Campaign
        </span>
      </v-btn>
    </template> -->
    <v-form>
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">New Campaign</div>
        </v-col>

        <v-col cols="6" class="text-right" align="center" justify="end">
          <!-- <v-btn outlined class="rounded-xl" style="color: #1a347f">
            <span class="text-center fn14-weight7"> Save Draft </span>
          </v-btn> -->
          <v-btn outlined class="rounded-xl ml-2" style="color: #1a347f">
            <span class="text-center fn14-weight7"> Preview </span>
          </v-btn>
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Rich Messages
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="10" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  placeholder="your message here"
                  v-model="form.campaign_name"
                  :rules="rules.required"
                />
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="start = 2"
              :disabled="!form.campaign_name"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            Personalised rich message types
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row class="fn12">
              <v-col cols="6" class="ma-0"> Select template </v-col>
              <v-col cols="9" class="d-flex pt-0">
                <v-col
                  v-for="(template, index) in templates"
                  :key="index"
                  cols="4"
                  class="pt-0"
                >
                  <v-card
                    max-width="135"
                    width="135"
                    max-height="135"
                    height="135"
                    style="background: #e5e5e5"
                    class="d-inline-block pa-2"
                    elevation="0"
                    active-class="select"
                  >
                    <div class="pa-2" v-if="template.no === 1">
                      <v-card
                        style="background: #c4c4c4"
                        class="rounded-0 mx-auto"
                        width="98"
                        height="33"
                        max-width="98"
                        max-height="33"
                        elevation="0"
                      ></v-card>
                      <v-row no-gutters class="mt-2">
                        <v-col class="pa-0" cols="4">
                          <v-card
                            width="31"
                            height="26"
                            max-width="31"
                            max-height="26"
                            class="rounded-0"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                        <v-col class="" cols="6">
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mt-2">
                        <v-col class="pa-0" cols="4">
                          <v-card
                            width="31"
                            height="26"
                            max-width="31"
                            max-height="26"
                            class="rounded-0"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                        <v-col class="" cols="6">
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="template.no === 2" class="pa-2">
                      <v-row no-gutters>
                        <v-col class="pa-0" cols="4">
                          <v-card
                            width="31"
                            height="26"
                            max-width="31"
                            max-height="26"
                            class="rounded-0"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                        <v-col class="" cols="6">
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                          <v-card
                            width="65"
                            height="5"
                            max-width="65"
                            max-height="5"
                            class="rounded-0 mb-1"
                            style="background: #c4c4c4"
                            elevation="0"
                          ></v-card>
                        </v-col>
                      </v-row>
                      <v-card
                        style="background: #c4c4c4"
                        class="rounded-0 mx-auto mt-3"
                        width="98"
                        height="62"
                        max-width="98"
                        max-height="62"
                        elevation="0"
                      ></v-card>
                    </div>
                    <div class="mb-5" v-if="template.no === 3">
                      <v-card
                        style="background: #c4c4c4"
                        class="rounded-0 mx-auto my-4"
                        width="98"
                        height="90"
                        max-width="98"
                        max-height="90"
                        elevation="0"
                      ></v-card>
                    </div>
                  </v-card>
                </v-col>
              </v-col>
              <v-col
                cols="3"
                class="fn14 d-flex text-center align-center justify-center"
              >
                <div style="color: #1a347f">Create new custom</div>
              </v-col>
            </v-row>
            <v-btn color="primary" class="mr-2" @click="start = 3">
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 3" step="3" class="fn16">
            Information
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="12" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  placeholder="your information here"
                  v-model="form.message"
                  :rules="rules.required"
                />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="start = 4" :disabled="!form.message">
              Continue
            </v-btn>
            <v-btn @click="start = 2"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <!-- <v-col cols="12">
          <v-checkbox
            v-model="form.publish"
            label="Publish this banner immediately"
            color="indigo"
            hide-details
            class="mx-8"
          />
        </v-col> -->
        <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
          <v-btn
            :dark="start < 4 ? false : true"
            :disabled="start < 4"
            class="rounded-lg mt-2"
            :style="
              start < 4
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            block
            @click="submit"
          >
            <span class="text-center fn14-weight7"> Save Campaign </span>
          </v-btn>
          <v-btn
            class="rounded-lg mt-2"
            style="color: #9bbef2"
            outlined
            width="250"
            block
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    form: Object,
    dialog: Boolean,
    isEdit: {
      type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      dialog: false,
      start: 1,
      idEdit: "",
      templates: [
        {
          no: 1,
        },
        {
          no: 2,
        },
        {
          no: 3,
        },
      ],

      menufrom: false,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === "edit") {
          this.getRewardById(val.id);
        } else {
          this.$emit("clear");
        }
      },
      deeps: true,
    },
  },

  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    cancel() {
      if (this.isEdit) {
        this.start = 1;
        this.$emit("close");
      } else {
        this.start = 1;
        this.$emit("close");
        this.$emit("clear");
      }
    },
    async submit() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      this.form = {
        ...this.form,
        status: "CURRENT",
      };
      if (this.isEdit === "edit") {
        this.form = {
          ...this.form,
          id: this.idEdit,
        };
      }

      if (this.isEdit === "edit") {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/campaign/` + this.idEdit,
          this.form,
          auth
        );
        if (response.data.response_status === "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขcampaignสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllCampaign", "CURRENT");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขcampaignไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.$emit("close");
        this.$emit("clear");
      } else {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/campaign`,
          this.form,
          auth
        );
        console.log("creatPost", response);
        if (response.data.response_status === "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างcampaignสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllCampaign", "CURRENT");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างcampaignไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.$emit("close");
        this.$emit("clear");
      }
    },

    async getRewardById(id) {
      console.log(id);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/campaign/` + id
      );
      const { data } = response.data;
      console.log("responseById", data);
      this.form.campaign_name = data.campaign_name;
      this.form.message = data.message;
      this.idEdit = data.id;
    },
  },
};
</script>
