<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Campaigns (12)</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              dark
              class="rounded-xl"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              @click="dialogStepFormOpen()"
            >
              <span class="text-center mx-4 fn14-weight7">
                <a-icon type="plus" class="mr-2" />
                New Campaign
              </span>
            </v-btn>
            <stepForm
              :dialog="dialog"
              :form.sync="form"
              :isEdit="isEdit"
              :items="itemEdit"
              @getAllCampaign="getAllCampaign(statusDefault)"
              @clear="clear"
              @close="closeDialog"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" color="#2196f3">
          <v-tab
            v-for="item in tabItems"
            :key="item.tab"
            style="font-weight: 600"
            class="fn14"
            @click="cellItems(item.key)"
          >
            <span>{{ item.tab }}</span>
          </v-tab>
        </v-tabs>
        <v-card class="rounded-lg">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            style="font-weight: 700"
          >
            <template v-slot:header.id="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.campaign_name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.message="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.schedule_time="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.cost="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.schedule_status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.schedule_status`]="{ item }">
              <span
                class="fn14-weight7"
                :style="
                  item.schedule_status === 'Success' ? 'color: green;' : ''
                "
                >{{ item.schedule_status }}</span
              >
            </template>
            <template v-slot:[`item.message`]="{ item }">
              <div
                style="
                  white-space: nowrap;
                  width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.message }}
              </div>
            </template>
            <template v-slot:[`item.cost`]="{ item }">
              <div class="fn14-weight7">
                Users: <span>{{ item.uses }}</span>
              </div>
              <div class="fn14-weight7">
                Cost: <span>{{ item.cost }}</span>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="left" class="my-2">
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7"
                  style="color: #4f4f4f; width: 100px"
                >
                  Preview
                </v-btn>
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7 mx-2 mb-2"
                  style="color: #4f4f4f; width: 100px"
                  @click="UpdateCompaign(item)"
                >
                  Edit
                </v-btn>

                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7"
                  style="color: #4f4f4f; width: 100px"
                >
                  Cancel
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormCampaign";
export default {
  components: {
    draggable,
    Loading,
    stepForm,
  },
  data() {
    return {
      dialog: false,
      isEdit: "",
      itemEdit: {},
      statusDefault: "",
      items: [],
      headers: [
        { text: "ID", value: "id", width: "70" },
        { text: "Campaign Name", value: "campaign_name", width: "200" },
        { text: "Messages", value: "message", height: "100", width: "100" },
        { text: "Schedule Time", value: "schedule_time", width: 160 },
        { text: "Uses & Cost", value: "cost", width: "100" },
        { text: "Scheduling Status", value: "schedule_status" },
        { text: "Action", value: "action", width: "230" },
      ],
      tab: null,
      tabItems: [
        {
          tab: "Current",
          content: "Schedule",
          key: "CURRENT",
          items: [
            // {
            //   id: 1,
            //   name: "Happy Farm",
            //   message:
            //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            //   time: "09/02/2020 17:00",
            //   uses: "1",
            //   cost: "0.3",
            //   status: "Success",
            // },
          ],
        },
        { tab: "Sent", content: "Sent", key: "SENT" },
        { tab: "Cancelled", content: "Cancelled", key: "CANCELLED" },
        { tab: "Failures", content: "Failures", key: "FAILURES" },
      ],
      //       campaignUrl: null
      // campaign_name: "test add"
      // cost: null
      // create_by: null
      // createdAt: "2022-02-21T10:52:00.282Z"
      // id: 6
      // message: null
      // schedule_status: null
      // schedule_time: null
      // status: null
      // update_by: null
      // updatedAt: "2022-02-21T10:52:00.282Z"
      // userAmt: null
      form: {
        campaign_name: "",
        // date: new Date().toISOString().substr(0, 10),
        message: "",
        // picture_1: "",
        // menufrom: false,
        // publish: false,
      },
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.getAllCampaign("CURRENT");
  },
  methods: {
    async getAllCampaign(val) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/campaign?status=${val}`
      );
      this.items = response.data.data;
      for (let i in this.items) {
        this.items.count = parseInt(i) + 1;
      }
    },
    UpdateCompaign(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.isEdit = "edit";
      this.itemEdit = val;
      this.dialog = true;
      // this.$router.push("EditQuestionnaire");
    },
    ViewQuestionnaire(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewQuestionnaire");
    },
    async DeleteQuestionnaire(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brandsxx/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },

    dialogStepFormOpen() {
      this.clear();
      this.itemEdit = null;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    clear() {
      this.form = {
        campaign_name: "",
        // date: new Date().toISOString().substr(0, 10),
        message: "",
        // picture_1: "",
        // menufrom: false,
        // publish: false,
      };
    },
    cellItems(val) {
      this.getAllCampaign(val);
      // if (val == "CURRENT") {
      //   this.getAllCampaign(val);
      // } else if (val == "SENT") {
      //   this.getAllCampaign(val);
      // } else if (val == "CANCELLED") {
      //   this.getAllCampaign(val);
      // } else if (val == "FAILURES") {
      //   this.getAllCampaign(val);
      // }
    },
  },
};
</script>

<style scoped>
.active {
  /* color: rgba(255, 255, 255, 0) !important; */
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
