<template>
  <div>
    <ManageCampaign />
  </div>
</template>
<script>
import ManageCampaign from "@/components/Campaign/ManageCampaign";
export default {
  components: {
    ManageCampaign,
  },
  created() {},
};
</script>
